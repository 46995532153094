import { Reference } from '@apollo/client';
import { FieldMergeFunction } from '@apollo/client/cache/inmemory/policies';

export const mergeListShipments: FieldMergeFunction = (
  existing,
  incoming = { nodes: [] },
) => {
  const existingNodes: Reference[] = existing?.nodes ?? [];

  const filtered: ListNodesPayloadRef['nodes'] = [];
  const seen = new Set<string>();
  const allNodes = [...existingNodes, ...incoming.nodes];

  allNodes.forEach((pr) => {
    if (seen.has(pr.__ref)) {
      return;
    }
    seen.add(pr.__ref);
    filtered.push(pr);
  });

  return {
    ...incoming,
    nodes: filtered,
  };
};

export type ListNodesPayloadRef = {
  cursor: string;
  nodes: Reference[];
};
