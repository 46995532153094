import CommandMenu, {
  CommandMenuContext,
} from '@components/command-palette/CommandPalette';
import { useCommandMenu } from '@components/command-palette/useCommandMenu';
import React from 'react';

export type Props = {
  children: React.ReactNode;
};

const CommandMenuProvider = ({ children }: Props) => {
  const value = useCommandMenu();
  return (
    <CommandMenuContext.Provider value={value}>
      {children}
      <CommandMenu />
    </CommandMenuContext.Provider>
  );
};

export default CommandMenuProvider;
