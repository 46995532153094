import { DriverFragment } from '@/generated/graphql';
import CommandItem from '@components/command-palette/CommandItem';
import { formatRelativeIso8601DateTimeStr } from '@packfleet/datetime';
import React from 'react';

export type Props = {
  driver: DriverFragment;
  onSelect: (id: string) => void;
};

const DriverCommandItem = ({ driver, onSelect }: Props) => {
  return (
    <CommandItem key={driver.id} value={driver.id} onSelect={onSelect}>
      <div className="flex items-baseline gap-5">
        <div>
          <span className="">🚙</span>
        </div>
        <p className="font-semibold">
          {driver.name}
          {driver.archived ? ' (deactivated)' : null}
        </p>
      </div>
      <div className="hidden items-center justify-end gap-4 pr-2 md:flex">
        <div className="text-base text-secondary">
          {formatRelativeIso8601DateTimeStr(driver.createdAt)}
        </div>
      </div>
    </CommandItem>
  );
};

export default DriverCommandItem;
