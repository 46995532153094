import { Reference } from '@apollo/client';

export const mergePaginatedNodes = (
  existing: ListNodesPayloadRef | undefined,
  incoming: ListNodesPayloadRef,
) => {
  if (!existing) {
    return incoming;
  }
  const filtered: ListNodesPayloadRef['nodes'] = [];
  const seen = new Set<string>();
  const allNodes = [...existing.nodes, ...incoming.nodes];

  allNodes.forEach((pr) => {
    if (seen.has(pr.__ref)) {
      return;
    }
    seen.add(pr.__ref);
    filtered.push(pr);
  });

  return {
    ...incoming,
    nodes: filtered,
  };
};

export type ListNodesPayloadRef = {
  cursor: string;
  nodes: Reference[];
};
