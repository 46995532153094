import CommandGroup from '@components/command-palette/CommandGroup';
import DriverCommandItem from '@components/command-palette/DriverCommandItem';
import OrganizationCommandItem from '@components/command-palette/OrganizationCommandItem';
import UserCommandItem from '@components/command-palette/UserCommandItem';
import { Loading } from '@packfleet/ui';
import clsx from 'clsx';
import { Command } from 'cmdk';
import {
  useSearchDeliveryVehiclesLazyQuery,
  useSearchDriversLazyQuery,
  useSearchExternalShipmentsLazyQuery,
  useSearchOrganizationsLazyQuery,
  useSearchShipmentsLazyQuery,
  useSearchUsersLazyQuery,
} from 'generated/graphql';
import useDebounce from 'hooks/useDebounce';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Routes, linkTo } from '../../utilities/routes';
import AppNavCommandItem, { APP_NAV_ITEMS } from './AppNavCommandItem';
import { DeliveryVehicleItem } from './DeliveryVehicleCommandItem';
import ShipmentCommandItem from './ShipmentCommandItem';

export enum SearchEntity {
  organization = 'organization',
  user = 'user',
  shipment = 'shipment',
  driver = 'driver',
  externalShipment = 'externalShipment',
  deliveryVehicles = 'deliveryVehicles',
}

export type CommandMenuContextValue = {
  open: boolean;
  setOpen: (open: boolean | ((open: boolean) => boolean)) => void;
  menuParams: {
    restrictSearchToEntities?: SearchEntity[];
  };
  setMenuParams: (params: CommandMenuContextValue['menuParams']) => void;
};

export const CommandMenuContext =
  React.createContext<CommandMenuContextValue | null>(null);

const CommandMenu = () => {
  const ref = useRef(null);
  const router = useRouter();
  const context = useContext(CommandMenuContext);
  if (!context) {
    throw new Error('Command menu requires a context');
  }
  const { open, setOpen, menuParams } = context;
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 100);
  const [searchShipments, { data: shipmentsData, loading: shipmentsLoading }] =
    useSearchShipmentsLazyQuery();
  const [searchUsers, { data: usersData, loading: usersLoading }] =
    useSearchUsersLazyQuery();
  const [searchDrivers, { data: driversData, loading: driversLoading }] =
    useSearchDriversLazyQuery();
  const [
    searchOrganizations,
    { data: organizationsData, loading: organizationsLoading },
  ] = useSearchOrganizationsLazyQuery();
  const [
    searchExternalShipments,
    { data: externalShipmentsData, loading: externalShipmentsLoading },
  ] = useSearchExternalShipmentsLazyQuery();
  const [
    searchDeliveryVehicles,
    { data: deliveryVehiclesData, loading: deliveryVehiclesLoading },
  ] = useSearchDeliveryVehiclesLazyQuery();

  // Toggle the menu when ⌘K is pressed
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key?.toLowerCase() === 'k' && e.metaKey) {
        setSearch('');
        setOpen((open) => !open);
        return;
      }

      if (!open) return;

      if (e.key === 'Escape') {
        setOpen(false);
        return;
      }

      for (const nav of APP_NAV_ITEMS) {
        if (!nav.keyboard) {
          continue;
        }
        if (
          e.key?.toLowerCase() === nav.keyboard.key.toLowerCase() &&
          ((e.metaKey && nav.keyboard.modifier === '⌘') ||
            (e.shiftKey && nav.keyboard.modifier === '⇧'))
        ) {
          e.preventDefault();
          setOpen(false);
          void router.push(nav.href);
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [open]);

  const showOrgs =
    !menuParams.restrictSearchToEntities ||
    menuParams.restrictSearchToEntities.includes(SearchEntity.organization);
  const showUsers =
    !menuParams.restrictSearchToEntities ||
    menuParams.restrictSearchToEntities.includes(SearchEntity.user);
  const showShipments =
    !menuParams.restrictSearchToEntities ||
    menuParams.restrictSearchToEntities.includes(SearchEntity.shipment);
  const showExternalShipments =
    !menuParams.restrictSearchToEntities ||
    menuParams.restrictSearchToEntities.includes(SearchEntity.externalShipment);
  const showDrivers =
    !menuParams.restrictSearchToEntities ||
    menuParams.restrictSearchToEntities.includes(SearchEntity.driver);
  const showDeliveryVehicles =
    !menuParams.restrictSearchToEntities ||
    menuParams.restrictSearchToEntities.includes(SearchEntity.deliveryVehicles);

  const showAppNav = !menuParams.restrictSearchToEntities;

  useEffect(() => {
    if (!search) return;
    if (debouncedSearch) {
      if (showOrgs) {
        void searchOrganizations({ variables: { query: search } });
      }
      if (showShipments) {
        void searchShipments({ variables: { query: search } });
      }
      if (showExternalShipments) {
        void searchExternalShipments({ variables: { query: search } });
      }
      if (showDrivers) {
        void searchDrivers({ variables: { query: search } });
      }
      if (showUsers) {
        void searchUsers({ variables: { query: search } });
      }
      if (showDeliveryVehicles) {
        void searchDeliveryVehicles({
          variables: { input: { query: search } },
        });
      }
    }
  }, [
    debouncedSearch,
    search,
    showOrgs,
    showDrivers,
    showUsers,
    showShipments,
    showExternalShipments,
    showDeliveryVehicles,
  ]);

  const organizations = organizationsData?.searchOrganizations?.nodes ?? [];
  const shipments = shipmentsData?.searchShipments?.nodes ?? [];
  const externalShipments =
    externalShipmentsData?.searchExternalShipments?.nodes ?? [];
  const drivers = driversData?.searchDrivers?.drivers ?? [];
  const users = usersData?.searchUsers?.nodes ?? [];
  const deliveryVehicles =
    deliveryVehiclesData?.searchDeliveryVehicles?.deliveryVehicles ?? [];

  const appNavItems = APP_NAV_ITEMS.filter((item) =>
    item.title.toLowerCase().includes(search.toLowerCase()),
  );

  const loading =
    shipmentsLoading ||
    usersLoading ||
    driversLoading ||
    organizationsLoading ||
    externalShipmentsLoading ||
    deliveryVehiclesLoading;

  const noResults =
    search.length > 0 &&
    !organizations.length &&
    !shipments.length &&
    !externalShipments.length &&
    !drivers.length &&
    !users.length &&
    !appNavItems.length &&
    !deliveryVehicles.length;

  return open ? (
    <div
      ref={ref}
      className="absolute top-0 left-0 z-30 flex h-screen w-screen animate-scaleIn justify-center bg-primary bg-opacity-90"
      onClick={(e) => {
        // Close the palette if clicking not in the input box
        if (ref.current === (e.target as Node)) {
          setOpen(false);
        }
      }}
    >
      <Command
        label="Global Command Menu"
        className="bg-white h-max max-h-[80vh] w-full max-w-screen-sm overflow-auto rounded-md border border-light bg-primary drop-shadow-lg md:mt-40"
        shouldFilter={false}
      >
        <div className="relative">
          <Command.Input
            autoFocus
            className="w-full p-4 text-lg focus:outline-0"
            placeholder="Type a command or search..."
            value={search}
            onValueChange={setSearch}
          />
          <div className="absolute top-0 right-0 bottom-0 w-8 pr-4 flex items-center justify-center">
            <Loading when={loading} noMargin />
          </div>
        </div>
        <Command.List
          className={clsx({
            ['border-t border-primary pt-4']: !loading || !noResults,
          })}
        >
          {noResults && !loading ? (
            <div className="px-4 pb-4 text-center text-secondary">
              No results
            </div>
          ) : null}
          {showOrgs ? (
            <CommandGroup heading="Merchants" items={organizations}>
              {(item) => (
                <OrganizationCommandItem
                  organization={item}
                  onSelect={async (id) => {
                    await router.push(linkTo(Routes.merchant, { id }));
                    setOpen(false);
                  }}
                />
              )}
            </CommandGroup>
          ) : null}
          {showShipments ? (
            <CommandGroup heading="Shipments" items={shipments}>
              {(item) => (
                <ShipmentCommandItem
                  shipment={item}
                  onSelect={async (id) => {
                    await router.push(linkTo(Routes.shipment, { id }));
                    setOpen(false);
                  }}
                />
              )}
            </CommandGroup>
          ) : null}
          {showExternalShipments ? (
            <CommandGroup
              heading="Nationwide Shipments"
              items={externalShipments}
            >
              {(item) => (
                <ShipmentCommandItem
                  shipment={item}
                  onSelect={async (id) => {
                    await router.push(linkTo(Routes.externalShipment, { id }));
                    setOpen(false);
                  }}
                />
              )}
            </CommandGroup>
          ) : null}
          {showDrivers ? (
            <CommandGroup heading="Drivers" items={drivers}>
              {(item) => (
                <DriverCommandItem
                  driver={item}
                  onSelect={async (id) => {
                    await router.push(linkTo(Routes.driver, { id }));
                    setOpen(false);
                  }}
                />
              )}
            </CommandGroup>
          ) : null}
          {showUsers ? (
            <CommandGroup heading="Users" items={users}>
              {(item) => (
                <UserCommandItem
                  user={item}
                  onSelect={async (id) => {
                    await router.push(linkTo(Routes.user, { id }));
                    setOpen(false);
                  }}
                />
              )}
            </CommandGroup>
          ) : null}
          {showDeliveryVehicles ? (
            <CommandGroup heading="Delivery vehicles" items={deliveryVehicles}>
              {(item) => {
                return (
                  <DeliveryVehicleItem
                    deliveryVehicle={item}
                    onSelect={async () => {
                      await router.push(
                        linkTo(Routes.deliveryVehicle, { id: item.id }),
                      );
                      setOpen(false);
                    }}
                  />
                );
              }}
            </CommandGroup>
          ) : null}
          {showAppNav ? (
            <CommandGroup heading="App navigation" items={appNavItems}>
              {(item) => {
                return (
                  <AppNavCommandItem
                    item={item}
                    onSelect={async () => {
                      await router.push(item.href);
                      setOpen(false);
                    }}
                  />
                );
              }}
            </CommandGroup>
          ) : null}
        </Command.List>
      </Command>
    </div>
  ) : null;
};

export default CommandMenu;
