import { DeliveryVehicleFragment } from '@/generated/graphql';
import CommandItem from '@components/command-palette/CommandItem';
import { formatRelativeIso8601DateTimeStr } from '@packfleet/datetime';
import React from 'react';

export type Props = {
  deliveryVehicle: DeliveryVehicleFragment;
  onSelect: (id: string) => void;
};

export const DeliveryVehicleItem = ({ deliveryVehicle, onSelect }: Props) => {
  return (
    <CommandItem
      key={deliveryVehicle.id}
      value={deliveryVehicle.id}
      onSelect={onSelect}
    >
      <div className="flex items-baseline gap-5">
        <div>🚛</div>
        <div>
          <p className="font-semibold">
            {deliveryVehicle.licensePlate}
            {deliveryVehicle.ownedByEmployer
              ? ` (${deliveryVehicle.ownedByEmployer.name})`
              : null}
          </p>
          {deliveryVehicle.model ? (
            <p className="text-sm text-secondary">
              {deliveryVehicle.model.manufacturer} {deliveryVehicle.model.name}
            </p>
          ) : null}
        </div>
      </div>
      <div className="hidden items-center justify-end gap-4 pr-2 md:flex">
        <div className="text-base text-secondary">
          created {formatRelativeIso8601DateTimeStr(deliveryVehicle.createdAt)}
        </div>
      </div>
    </CommandItem>
  );
};
