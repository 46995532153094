import { useCurrentUser } from '@/hooks/useCurrentUser';
import {
  StaffRole,
  useListTasksQuery,
  useViewTaskMutation,
} from 'generated/graphql';
import { usePollWhenFocused } from 'hooks/usePollWhenFocused';
import { useCallback } from 'react';
import { SUPPORTED_TASK_TYPES } from '../constants';

export const useTasksInfo = () => {
  const [setViewingTask] = useViewTaskMutation();
  const currentUser = useCurrentUser();
  const {
    data: listTasksData,
    error: listTasksError,
    refetch: listTasksRefetch,
  } = useListTasksQuery({
    variables: {
      input: {
        supportedTaskTypes: SUPPORTED_TASK_TYPES,
      },
    },
    skip: !areTasksEnabledForUser(currentUser?.staffMember),
  });
  usePollWhenFocused({ interval: 10000, refetch: listTasksRefetch });

  const setIsViewing = useCallback(
    async (taskId: string, isViewing: boolean) => {
      await setViewingTask({
        variables: { input: { taskId, isViewing } },
      });
    },
    [setViewingTask],
  );

  return {
    setIsViewing,
    tasks: listTasksData?.listTasks.tasks,
    error: listTasksError?.message || listTasksData?.listTasks.error?.message,
  };
};

function areTasksEnabledForUser(
  staffMember: { role: StaffRole } | null | undefined,
): boolean {
  return (
    !!staffMember &&
    staffMember.role !== StaffRole.Warehouse &&
    staffMember.role !== StaffRole.WarehouseLead &&
    staffMember.role !== StaffRole.Driver
  );
}
