import CommandItem from '@components/command-palette/CommandItem';
import PackStatusPill from '@components/shipment/PackStatusPill';
import { Shipment } from '@packfleet/ui';
import React from 'react';
import { PackStatus } from '../../generated/graphql';

export type Props = {
  shipment: {
    id: string;
    status: PackStatus;
    organization?: {
      name: string;
    } | null;
    trackingNumber: string;
    packs: { id: string; status: PackStatus }[];
    createdAt: string;
    carrierCode?: string;
  };
  onSelect: (id: string) => void;
};

const ShipmentCommandItem = ({ shipment, onSelect }: Props) => {
  return (
    <CommandItem key={shipment.id} value={shipment.id} onSelect={onSelect}>
      <div className="flex w-full items-baseline gap-4">
        <div className="font-semibold">
          <span className="mr-1">{shipment.packs.map(() => `📦`)}</span>
          {shipment.trackingNumber}
          {shipment.carrierCode ? (
            <div className="flex gap-1 items-center">
              <span className="text-xs font-normal text-secondary">
                Delivered by
              </span>{' '}
              <Shipment.ExternalCarrierLogo
                carrierCode={shipment.carrierCode}
              />
            </div>
          ) : null}
        </div>
        <div className="text-secondary">{shipment.organization?.name}</div>
      </div>
      <div className="flex items-center gap-4 pr-2 text-sm md:justify-end">
        <PackStatusPill status={shipment.status} />
      </div>
    </CommandItem>
  );
};

export default ShipmentCommandItem;
