import cs from 'classnames';
import { ReactNode } from 'react';

export type Props = {
  color?:
    | 'danger'
    | 'warning'
    | 'info'
    | 'success'
    | 'neutral'
    | 'accent'
    | 'brandDark'
    | 'brandBright';
  mode?: 'primary' | 'secondary';
  className?: string;
  children?: ReactNode;
};

const Pill = ({
  color = 'info',
  children,
  mode = 'secondary',
  className,
}: Props) => {
  const primaryColors = {
    danger: 'bg-danger text-primaryInverted',
    warning: 'bg-warning text-primaryInverted',
    info: 'bg-info text-primaryInverted',
    success: 'bg-success text-primaryInverted',
    neutral: 'bg-neutral text-primaryInverted',
    brandDark: 'bg-brandDark text-primaryInverted',
    brandBright: 'bg-brandBright text-primary',
    accent: 'bg-[#6550B9] text-primaryInverted',
  };

  const secondaryColors = {
    danger: 'bg-dangerLight text-danger',
    warning: 'bg-warningLight text-warning',
    info: 'bg-infoLight text-info',
    success: 'bg-successLight text-success',
    neutral: 'bg-neutralLight text-neutral',
    brandDark: 'bg-brandDark/10 text-brandDark',
    brandBright: 'bg-brandBright/10 text-brandBright',
    accent: 'bg-[#afa4db] text-[#42337f]',
  };

  return (
    <span
      className={cs(
        'rounded-full px-3 py-1.5 leading-none whitespace-nowrap',
        className,
        {
          [primaryColors[color]]: mode === 'primary',
          [secondaryColors[color]]: mode === 'secondary',
        },
      )}
    >
      {children}
    </span>
  );
};

export default Pill;
