import Pill from '@components/pill/Pill';
import cs from 'classnames';
import React from 'react';
import { PackStatus } from '../../generated/graphql';
import {
  formatPackStatus,
  getPackStatusColor,
} from '../../utilities/shipments';

export type Props = { status: PackStatus; count?: number; className?: string };

const PackStatusPill = ({ status, count, className }: Props) => {
  return (
    <Pill
      color={getPackStatusColor(status)}
      className={cs('inline-block', className)}
    >
      {count ? `${count} ` : null}
      {formatPackStatus(status)}
    </Pill>
  );
};

export default PackStatusPill;
