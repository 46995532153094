import React from 'react';

export const ErrorPage = () => (
  <div className="flex h-screen w-full flex-col items-center justify-center p-6">
    <div className="flex max-w-sm flex-col items-center">
      <h2 className="mb-4 text-6xl font-bold">Oh no</h2>
      <p className="text-center text-secondary">
        Sorry, something has gone wrong. Engineers have already been notified,
        but it would be helpful if you could tell us what happened.
      </p>
    </div>
  </div>
);
