import { CommandMenuContextValue } from '@components/command-palette/CommandPalette';
import { useCallback, useState } from 'react';

export function useCommandMenu() {
  const [menuOpen, _setMenuOpen] = useState(false);
  const [menuParams, setMenuParams] = useState<
    CommandMenuContextValue['menuParams']
  >({});

  const setMenuOpen = useCallback(
    (open: boolean | ((open: boolean) => boolean)) => {
      _setMenuOpen(open);
      // Reset search restrictions when menu is closed
      if (!open) {
        setMenuParams((menuParams) => ({
          ...menuParams,
          restrictSearchToEntities: undefined,
        }));
      }
    },
    [],
  );

  return {
    open: menuOpen,
    setOpen: setMenuOpen,
    menuParams,
    setMenuParams,
  };
}
