import { Command } from 'cmdk';
import React from 'react';

export type Props<T> = {
  heading: string;
  items: T[];
  children: (item: T) => React.ReactNode;
};

export default function CommandGroup<T>({
  heading,
  items,
  children,
}: Props<T>) {
  if (items.length === 0) {
    return null;
  }
  return (
    <Command.Group
      heading={heading}
      className="px-4 pb-4 text-sm text-secondary"
    >
      {items.map((item) => children(item))}
    </Command.Group>
  );
}
