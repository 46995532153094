import CommandItem from '@components/command-palette/CommandItem';
import { formatRelativeIso8601DateTimeStr } from '@packfleet/datetime';
import React from 'react';

export type Props = {
  organization: {
    id: string;
    name: string;
    createdAt: string;
    emoji?: string | null;
  };
  onSelect: (id: string) => void;
};

const OrganizationCommandItem = ({ organization, onSelect }: Props) => {
  return (
    <CommandItem
      key={organization.id}
      value={organization.id}
      onSelect={onSelect}
    >
      <div className="flex items-baseline gap-5">
        <div>
          <span className="">{organization.emoji ?? '🏢'}</span>
        </div>
        <p className="font-semibold">{organization.name}</p>
      </div>
      <div className="hidden items-center justify-end gap-4 pr-2 md:flex">
        <div className="text-base text-secondary">
          Joined {formatRelativeIso8601DateTimeStr(organization.createdAt)}
        </div>
      </div>
    </CommandItem>
  );
};

export default OrganizationCommandItem;
