// Update if you would like to force a build of this service without changing any logic
// Force CI build: 2024-08-19
import '../styles/globals.css';
import '../styles/nprogress.css';
import '../styles/modal.css';
import '../styles/nprogress.css';

import '../styles/react-calendar-heatmap.css';
import 'react-loading-skeleton/dist/skeleton.css';

import { ApolloProvider } from '@apollo/client';
import CommandMenuProvider from '@components/command-palette/CommandMenuProvider';
import { AnalyticsProvider, PageView } from '@packfleet/analytics';
import * as Sentry from '@sentry/nextjs';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { ReactElement, ReactNode } from 'react';

import { ErrorPage } from '@components/error/ErrorPage';
import { TasksContextProvider } from '@components/tasks/sidebar/useTasksContext';
import { useApollo } from '../graphql/client';
import { useAutoRefresh } from '../hooks/useAutoRefresh';
import { initializeFirebase } from '../utilities/firebase';
import { initializeProgressBar } from '../utilities/progress-bar';

initializeFirebase();
initializeProgressBar();

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function App({ Component, pageProps }: AppPropsWithLayout) {
  const apolloClient = useApollo(pageProps);
  const getLayout = Component.getLayout ?? ((page) => page);
  const router = useRouter();

  useAutoRefresh();

  return (
    <Sentry.ErrorBoundary fallback={<ErrorPage />} showDialog>
      <AnalyticsProvider
        apiKey={process.env.NEXT_PUBLIC_POSTHOG_API_KEY ?? ''}
        autocapture={false}
      >
        <PageView path={router.asPath}>
          <ApolloProvider client={apolloClient}>
            <TasksContextProvider>
              <CommandMenuProvider>
                {getLayout(<Component {...pageProps} />)}
              </CommandMenuProvider>
            </TasksContextProvider>
          </ApolloProvider>
        </PageView>
      </AnalyticsProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
