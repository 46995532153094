import {
  getAuth,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { API_BASE_URL } from '../../graphql/client';
import { axiosInstance } from '../axios';
import { Routes } from '../routes';

export async function login(email: string, password: string) {
  const auth = getAuth();
  const result = await signInWithEmailAndPassword(auth, email, password);
  if (result.user.emailVerified) {
    // Only log user in if email is verified
    const token = await result.user.getIdToken();
    await Promise.all([
      // login to API domain (cross origin)
      axiosInstance.post(
        API_BASE_URL + Routes.apiLogin,
        { idToken: token },
        { withCredentials: true },
      ),
      // login to web domain (same origin)
      axiosInstance.post('/internal-api' + Routes.apiLogin, { idToken: token }),
    ]);
  }

  return result;
}

export async function logout() {
  const auth = getAuth();
  await signOut(auth);
  return await Promise.all([
    axiosInstance.post(
      API_BASE_URL + Routes.apiLogout,
      {},
      { withCredentials: true },
    ),
    axiosInstance.post('/internal-api' + Routes.apiLogout, {}),
  ]);
}

export async function loginAs(userId: string) {
  const auth = getAuth();

  const loginAsResponse = await fetch(API_BASE_URL + Routes.apiLoginAs, {
    method: 'POST',
    body: JSON.stringify({ userId }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  });
  const loginAsData = await loginAsResponse.json();
  const token = loginAsData.token;

  const result = await signInWithCustomToken(auth, token);
  const idToken = await result.user.getIdToken();

  await Promise.all([
    // login to API domain (cross origin)
    axiosInstance.post(
      API_BASE_URL + Routes.apiLogin,
      { idToken },
      { withCredentials: true },
    ),
    // login to web domain (same origin)
    axiosInstance.post('/internal-api' + Routes.apiLogin, { idToken }),
  ]);
}

export async function logoutAs() {
  const auth = getAuth();

  const logoutAsResponse = await fetch(API_BASE_URL + Routes.apiLogoutAs, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  });
  const logoutAsData = await logoutAsResponse.json();
  const token = logoutAsData.token;

  if (!token) {
    // No token means the user is already logged out
    return;
  }

  const result = await signInWithCustomToken(auth, token);
  const idToken = await result.user.getIdToken();

  await Promise.all([
    // login to API domain (cross origin)
    axiosInstance.post(
      API_BASE_URL + Routes.apiLogin,
      { idToken },
      { withCredentials: true },
    ),
    // login to web domain (same origin)
    axiosInstance.post('/internal-api' + Routes.apiLogin, { idToken }),
  ]);
}
