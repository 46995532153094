import { UserFragment } from '@/generated/graphql';
import CommandItem from '@components/command-palette/CommandItem';
import { formatRelativeIso8601DateTimeStr } from '@packfleet/datetime';
import React from 'react';

export type Props = {
  user: UserFragment;
  onSelect: (id: string) => void;
};

const UserCommandItem = ({ user, onSelect }: Props) => {
  return (
    <CommandItem key={user.id} value={user.id} onSelect={onSelect}>
      <div className="flex items-baseline gap-5">
        <div>👤</div>
        <div>
          <p className="font-semibold">
            {user.name}
            {user.deactivatedAt ? ' (deactivated)' : null}
          </p>
          <p className="text-sm text-secondary">{user.email}</p>
        </div>
      </div>
      <div className="hidden items-center justify-end gap-4 pr-2 md:flex">
        <div className="text-base text-secondary">
          joined {formatRelativeIso8601DateTimeStr(user.createdAt)}
        </div>
      </div>
    </CommandItem>
  );
};

export default UserCommandItem;
