import { useCallback, useEffect, useRef } from 'react';
import { useIsTabVisible } from './useIsTabVisible';

type Params = {
  interval: number;
  refetch: () => void;
  skip?: boolean;
};

export function usePollWhenFocused({ interval, refetch, skip }: Params) {
  const isTabVisible = useIsTabVisible();
  const firstRender = useRef(true);

  const refetchIfNotSkipped = useCallback(() => {
    if (!skip) {
      refetch();
    }
  }, [refetch, skip]);

  useEffect(() => {
    // We don't want to refetch immediately
    if (firstRender.current) {
      firstRender.current = false;
    } else if (isTabVisible) {
      // But we do if the tab has come back into focus
      refetchIfNotSkipped();
    }

    if (isTabVisible) {
      const intervalId = setInterval(() => refetchIfNotSkipped(), interval);
      return () => clearInterval(intervalId);
    }
  }, [interval, isTabVisible, refetchIfNotSkipped]);
}
