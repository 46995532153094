import { mergeListShipments } from '@/utilities/apollo-cache/merge-list-shipments';
import { ApolloClientOptions, ApolloLink, InMemoryCache } from '@apollo/client';
import { mergePaginatedNodes } from '../utilities/apollo-cache/merge-paginated-nodes';

export const apolloConfig = (
  link: ApolloLink,
): ApolloClientOptions<unknown> => {
  return {
    ssrMode: typeof window === 'undefined', // set to true for SSR
    connectToDevTools: true,
    link: link,
    cache: new InMemoryCache({
      typePolicies: {
        Organization: {
          fields: {
            shipments: {
              keyArgs: ['input', ['status', 'deliveryDate', 'collectionDate']],
              merge: mergeListShipments,
            },
          },
        },

        ShipmentsResult: {
          // Disable normalization
          // Objects that are not normalized are instead embedded within their parent object in the cache. You can't access these objects directly, but you can access them via their parent.
          // https://www.apollographql.com/docs/react/caching/cache-configuration/#disabling-normalization
          keyFields: false,
        },

        Query: {
          fields: {
            users: {
              keyArgs: ['input', ['organizationId']],
              merge: mergePaginatedNodes,
            },
            listShipments: {
              keyArgs: [
                'input',
                ['organizationId', 'status', 'deliveryDate', 'collectionDate'],
              ],
              merge: mergePaginatedNodes,
            },
            listExternalShipments: {
              keyArgs: [
                'input',
                [
                  'organizationId',
                  'status',
                  'carriers',
                  'deliveryDate',
                  'collectionDate',
                  'collectionLocationId',
                ],
              ],
              merge: mergePaginatedNodes,
            },
            listFees: {
              keyArgs: ['input', ['organizationId']],
              merge: mergePaginatedNodes,
            },
            listPaymentRuns: {
              keyArgs: [],
              merge: mergePaginatedNodes,
            },
            listRecurringPaymentRunItems: {
              keyArgs: (args) => [args?.input.userId || 'all-users'],
              merge: mergePaginatedNodes,
            },
            listEmployers: {
              keyArgs: [],
              merge: mergePaginatedNodes,
            },
            listDeliveryVehicles: {
              keyArgs: [],
              merge: mergePaginatedNodes,
            },
            listVehiclesForDeliveryVehicle: {
              keyArgs: ['input', ['deliveryVehicleId']],
              merge: mergePaginatedNodes,
            },
            organization: {
              read(_, { args, toReference }) {
                return toReference({
                  __typename: 'Organization',
                  id: args?.id,
                });
              },
            },
            collectionLocation: {
              read(_, { args, toReference }) {
                return toReference({
                  __typename: 'CollectionLocation',
                  id: args?.id,
                });
              },
            },
          },
        },
      },
    }),
  };
};
