import { THINGS_LINKS } from '@components/things/things';
import { Command } from 'cmdk';
import React from 'react';
import { IconType } from 'react-icons';
import { TbChartBar, TbCompass } from 'react-icons/tb';
import { Routes, linkTo } from '../../utilities/routes';

export type AppNavItemType = {
  icon: IconType;
  title: string;
  href: string;
  keyboard?: {
    modifier: string;
    key: string;
  };
};

export const APP_NAV_ITEMS = [
  {
    icon: TbChartBar,
    title: 'Dashboard',
    href: linkTo(Routes.dashboard),
    keyboard: {
      modifier: '⌘',
      key: 'D',
    },
  },
  {
    icon: TbCompass,
    title: 'Routing',
    href: linkTo(Routes.routing),
    keyboard: {
      modifier: '⌘',
      key: 'R',
    },
  },
  ...THINGS_LINKS.filter((l) => l.showInCommandPalette).map((l) => ({
    icon: l.icon,
    title: l.title,
    href: l.href,
    keyboard: l.keyboardShortcut,
  })),
];

export type Props = { item: AppNavItemType; onSelect: () => void };

const AppNavCommandItem = ({ item, onSelect }: Props) => {
  return (
    <Command.Item
      className="flex w-full items-center justify-between rounded-md py-2 pr-4 pl-2 text-lg font-normal text-primary first-of-type:mt-2 aria-selected:bg-light"
      value={item.title}
      onSelect={onSelect}
    >
      <div className="flex items-center gap-5">
        <item.icon />
        {item.title}
      </div>
      {item.keyboard ? (
        <div className="flex items-center gap-2">
          <kbd
            key={item.keyboard.modifier}
            className="inline-flex h-5 w-5 items-center justify-center rounded bg-grey/30 p-2 font-mono text-xs uppercase"
          >
            {item.keyboard.modifier}
          </kbd>
          <kbd
            key={item.keyboard.key}
            className="inline-flex h-5 w-5 items-center justify-center rounded bg-grey/30 p-2 font-mono text-xs uppercase"
          >
            {item.keyboard.key}
          </kbd>
        </div>
      ) : null}
    </Command.Item>
  );
};

export default AppNavCommandItem;
