export enum ShipmentTypes {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export enum ExternalCarrierCode {
  dpd = 'dpd',
  dhlUK = 'dhl-uk',
}

export const SUPPORTED_CARRIERS = [
  ExternalCarrierCode.dhlUK,
  ExternalCarrierCode.dpd,
];

export function formatCarrierName(carrierCode: ExternalCarrierCode | string) {
  switch (carrierCode) {
    case ExternalCarrierCode.dhlUK:
      return 'DHL';
    case ExternalCarrierCode.dpd:
      return 'DPD';
    default:
      return carrierCode;
  }
}
