import { useEffect, useState } from 'react';

export function useIsTabVisible() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const updateIsVisible = () =>
      setIsVisible(document.visibilityState !== 'hidden');

    updateIsVisible();

    window.addEventListener('visibilitychange', updateIsVisible);

    return () => {
      window.removeEventListener('visibilitychange', updateIsVisible);
    };
  }, []);

  return isVisible;
}
