import { Command } from 'cmdk';
import React from 'react';

export type Props = {
  onSelect: (id: string) => void;
  value: string;
  children: React.ReactNode;
};

const CommandItem = ({ value, onSelect, children }: Props) => {
  return (
    <Command.Item
      className="grid w-full grid-cols-1 items-center gap-2 rounded-md py-2 pl-2 text-lg font-normal text-primary first-of-type:mt-2 aria-selected:bg-light md:grid-cols-2"
      value={value}
      onSelect={onSelect}
    >
      {children}
    </Command.Item>
  );
};

export default CommandItem;
